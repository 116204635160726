<template>
    <div class="video">
        <VideoBlock v-bind="video" preserveAspectRatio />
        <PublicationSummary
            class="pub-summary"
            subtype="video"
            :title="title"
            :dek="dek"
            :contributors="contributors"
            :publishedAt="publishedAt"
            :showReadTime="false"
            :attributions="attributions"
        />
        <PublicationStreamBlocks v-if="streamContained?.length" :blocks="streamContained" class="stream" />
        <section class="disclaimer">
            <Typography variant="body-display-micro-italic">{{
                $t(
                    'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.',
                    1,
                    { locale: pageLanguage },
                )
            }}</Typography>
        </section>
    </div>
</template>
<script setup>
import VideoBlock from '@/components/blocks/VideoBlock.vue';
const { pageLanguage } = usePageDataStore();
const { getCenterTagsBySyndication } = useCenters();
const props = defineProps({
    publishedAt: {
        type: String,
    },
    title: {
        type: String,
    },
    syndications: {
        type: Object,
    },
    video: {
        type: Object,
        required: true,
    },
    contributors: {
        type: Array,
    },
    dek: {
        type: Object,
        default: () => {},
    },
    tags: {
        type: Array,
    },
    locale: {
        type: String,
        default: '',
    },
    streamContained: {
        type: Array,
        default: () => [],
    },
});

const attributions = useAttribution(props.syndications, props.parent);

const affiliationContent = getAffiliationContent(props.syndications);
const collectionContent = getCollectionContent(props.syndications);
const headerAttributesStore = useHeaderAttributesStore();
const combinedTags = getCenterTagsBySyndication(props.syndications, props.tags);

const { GTMCenter, GTMAuthors, GTMPrograms, GTMProjects, GTMRegions, GTMTopics } = useGTMContent(
    'syndication',
    props,
    affiliationContent,
    combinedTags,
    collectionContent,
);
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter,
        Doctype: 'Video',
        Expert: GTMAuthors,
        'Taxonomy Tags Program': GTMPrograms,
        'Taxonomy Special Project': GTMProjects,
        'Taxonomy Tags Regions': GTMRegions,
        'Taxonomy Tags Topics': GTMTopics,
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'grey' });
});
</script>
<style lang="scss" scoped>
.video {
    @include content-section;
    @include media-query(full) {
        max-width: calc(2 / 3 * 100%);
    }
    padding-top: vertical-space(6);
}
.pub-summary {
    padding: 2.4rem 0;
}
.stream {
    padding-top: 2.4rem;
}
.disclaimer {
    margin: 4.8rem auto;
    max-width: var(--body-text-max-width);
}
</style>
